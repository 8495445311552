/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: flex;
  }
  
  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
  
  .horizontallist {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 -5px;
  }

  