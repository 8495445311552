input[type='tel'] {
  font-family: 'Public Sans', sans-serif !important;
}

.phone-form-container > div {
  padding: 0;
}
.phone-form-container input[type='tel'] {
  font-size: 16px;
  width: 45px !important;
}

.store-report-datepicker-conttainer .css-3ok8xw {
  margin-right: 0;
}
